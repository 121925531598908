<template>
   <div>
     <div class="utility-page-wrap">
    <div class="utility-page-content w-form">
       <lottie-animation
                  :animation="NotFoundAnimation"
                  :animationSpeed="1.5"
                ></lottie-animation>
      <h2 class="title-404">Page Not Found</h2>
      <div class="_404-text">The page you are looking for doesn&#x27;t exist or has been moved.</div>
    </div>
  </div>
  </div>
</template>

<script>
import NotFound from "../assets/LottieAnimations/404.json";
import LottieAnimation from "../components/LottieAnimationComponent";
export default {
  name: 'PageNotFound',
  mounted() {
    window.scrollTo(0, 0)
  },
   components: {
    LottieAnimation,

  },
  data() {
    return {
      NotFoundAnimation: NotFound,
    };
  },
}
</script>

<style>

</style>
